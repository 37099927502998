@import '../../assets/constants/helpers/helpers.scss';

.group-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cong-text{
        height: 46px;
        font-style: normal;
        font-weight: $regular;
        font-size: 32px;
        line-height: 46px;
        text-align: center;
        color: $darckBlue;
        .feles{
            font-weight: $semiBold;
        }
    }
}

@media (max-width: 700px) {
    .step-form{
        .group-2{
            .cong-text{
                font-size: 20px;
                line-height: 1.5;
            }
        }
    }
}