@import '../../assets/constants/helpers/helpers.scss';

.home-page{
    background-color: $white;
    font-family: $font;
    border-radius: 10px;
    overflow: hidden;
}

@media (min-width: 700px) and (max-width: 1124px) {
    .home-page{
        .header{
            .text{
                .title{
                    .t{
                        font-size: 25px;
                        width: 40vw;
                    }
                }
                .sub-title{
                    margin-bottom: 5vh;
                    .t{
                        font-size: 20px;
                        width: 40vw;
                    }
                }
                .secend-text{
                    .t{
                        line-height: 1;
                        font-size: 20px;
                    }
                }
            }
            .message-love{
                .img{
                    width: 8vw;
                }
            }
        }
        .insurances{
            .insurance{
                width: 40vw;
                .description{
                    p{
                        font-size: 20px;
                        width: 40vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .home-page{
        .header{
            .text{
                .title{
                    .t{
                        font-size: 18px;
                        width: 40vw;
                    }
                }
                .sub-title{
                    margin-bottom: 5vh;
                    .t{
                        font-size: 16px;
                        width: 40vw;
                    }
                }
                .secend-text{
                    .t{
                        line-height: 1;
                        font-size: 16px;
                        width: 40vw;
                    }
                }
            }
            .message-love{
                .avi {
                    margin-top: 5vh;
                    width: 70vw;
                    font-size: 14px;
                }
                .img{
                    margin-top: 5vh;
                }
            }
            .message-rating{
                .top{
                    position: relative;
                    top: 9vh;
                }
                .avi{
                    position: relative;
                    top: 9vh;
                    font-size: 14px;
                }
            }
        }
        .our-insurances{
            .title{
                .center{
                    text-align: center;
                }
            }
        }
    }
}