@import '../../../../assets/constants/helpers/helpers.scss';

.form-setting{
    width: 95%;
    height: 540px;
    padding: 30px;
    background-color: $white;
    border-radius: 10px;
    .groupe{
        display: flex;
        width: 100%;
        input{
            width: 95%;
            background-color: $whitev2;  
            border-color: $whitev2;
            font-weight: $semiBold;
            font-size: 15px;
            line-height: 18px;
            color: $grayV2;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        label{
            font-family: $font;
            font-style: normal;
            font-weight: $regular;
            font-size: 15px;
            line-height: 18px;
            color: $black;
        }
    }
    .bar{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        .btns{
            display: flex;

            button{
                margin: 2px;
                width: 100px;
                height: 40px;
            }
        }
        .info{
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                height: 80px;
            }
            .name-email{
                text-align: left;
                margin-left: 10px;
                .name{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 18px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: $darck;
                }
                .email{
                    font-family: $font;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: $darck;
                }
            }
        }
    }

    .container-btn{
        width: 100%;
        display: flex;
        .btn{
            margin-left: auto;
        }
    }
}
.tab.option{
    font-family: $font;
    color: $darck;
    font-weight: bold;
}
.tabs.container{
    border-bottom: none !important;
    align-items: center;  
    justify-content: center;  
}
.tabs1.container1{
    border-bottom: none !important;
    align-items: center;  
    justify-content: center; 
    .css-heg063-MuiTabs-flexContainer{
        justify-content: center;
    } 
}

@media (max-width: 936px) {
    .form-setting{
        .bar{
            flex-direction: column;
        }
        .groupe{
            flex-direction: column;
            .field-1, .field-login{
                align-items: center;
                input{
                    text-align: center;
                }
                input::placeholder{
                    color: transparent;
                }
            }

        }
    }
}