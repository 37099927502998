@import '../../../assets/constants/helpers/helpers.scss';

.custome-button{
    height: 45px;
    width: 130px;
    background-color: $blue;
    clear: both;
    border-radius: 10px;
    text-shadow: none;
    display: block; 
    margin: 10px auto;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: $font;
    color: $lightGray ;
    transition: all 150ms ease-in-out ;
    &.full-width{
        width: 100%;
    }
    &.reverse{
        background-color: $white;
        border: 1px solid $gray;
        color: $gray;
        &:hover{
         opacity: 0.8;
        }

    }
    &:hover{
        opacity: 0.8;  
    }
    &:active{
       transform: scale(0.94);
    }
}