@import '../../assets/constants/helpers/helpers.scss';

.signin-page-individuel{
    background: linear-gradient(0deg, $transparentBlack, $transparentBlack), url('../../assets/images/bg-login.png') !important;
}

.signin-page-entreprise{
    background: linear-gradient(0deg, #cac9c97d, #cac9c97d);
}

.bg-entreprise{
    background-image: url('../../assets/images/bgEntreprise-login.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    height: calc(100% - 9vh);
    width: 100%;
    position: absolute;
    top: 9vh;
    z-index: 0;
}

.resetPass-page{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    font-family: $font ;
    overflow: hidden;
    .bg{
        width: 470px;
        height: 550px;
        position: absolute;
        top: 250px;
        left: 0;
        z-index: 0;
    }
    .container-login{
        width: 100%;
        min-height: calc(100vh - 130px);
        background-size: cover;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 80px;
        position: relative;
        z-index: 10;
        .wrapper-form{
            width: 430px;
            padding:15px 25px;
            margin: 0 auto;
            font-size: 16px;
            background-color: $white;
            border-radius: 5px;
            position: relative;
            left: 7vw;
            p{
                margin: 25px 0;
                text-align: start;
                margin: 25px 0;
                font-size: 27px;
                &.link{
                    text-align: center;
                    font-size: 16px;
                }
                .cliquez-ici{
                    text-decoration: underline;
                }
            }
            .error-message{
                background-color: $white;
                color: $danger;
                display: flex;
                width: 90%;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                box-shadow: 0 0 0 1px $pink inset, 0 0 0 0 transparent;
                border-radius: 5px;
                padding: 10px;
                justify-content: center;
                position: relative;
                margin: 0 auto;
                margin-bottom: 10px;
                .close{
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                }
                .alter-icon{
                    font-size: 40px;
                }
                .content{
                    width: 80%;
                    .header{
                        color: $danger;
                        font-size: 1.34285714em;
                    }
                    .list{
                        text-align: left;
                        padding: 0;
                        opacity: .85;
                        list-style-position: inside;
                        margin: 0.5em 0 0;
                        li {
                            position: relative;
                            list-style-type: none;
                            margin: 0 0 0.3em 1em;
                            padding: 0;
                        }
                    }
                }
            }
            form{
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                input::placeholder {
                    font-weight: bold;
                    font-size: 14px;
                    font-family: $font;
                    color: #878787;
                }
                input:focus{
                    &::placeholder{
                        font-size: 14px;
                        font-family: $font;
                        color: #373737;
                    }
                }
                .form-container-sing-in {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .options{
                        display:flex;
                        justify-content: space-between;
                        .checkbox{
                            position: relative;
                            display: inline-block;
                            -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                            outline: 0;
                            vertical-align: baseline;
                            font-style: normal;
                            text-align: start;
                            label{
                                color:$black !important;
                                font-size: 12px;
                                font-weight: $extraBold;
                                display: flex;
                                align-items: center;
                                height: 12px;
                            }
                        }
                        .text-right{
                            clear: both;
                            margin: 0 0 1em;
                            text-align: right !important;
                            font-size: 12px;
                            font-weight: $extraBold;
                            a{
                                color : $blue ;
                            }
                        }
                    }
                    .btn-connect{
                        position: relative;
                        top: 20px;
                    }
                    .error{
                      color: red;
                      font-size: 14px;
                      margin: 0px;
                    }
                }
            }
            p{
                a{
                    color: $blue;
                }
            }
        }
        .text{
            text-align: start;
            height: 270px;
            .simple-text{
                width: 491px;
                height: 31px;
                font-style: normal;
                font-weight: $regular;
                font-size: 25px;
                line-height: 31px;
                color: $white;
                &.bolder{
                    font-weight: $extraBold;
                    font-size: 51px;
                    line-height: 52px;
                }
            }
        }
    }
}

@media (min-width: 940px) and (max-width: 1124px) {
    .resetPass-page{
        .container-login{
            .text{
                h3{
                    .simple-text p{
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

@media (min-width: 700px) and (max-width: 940px) {
    .resetPass-page{
        .container-login{
            .text{
                h3{
                    .simple-text p{
                        font-size: 24px;
                    }
                }
                .simple-text p{
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width : 700px) {
    .resetPass-page{
        min-height: 100vh;
        .bg{
            position: fixed;
        }
        .container-login{
            padding: 10px;
            .text{
                display: none;
            }
            .wrapper-form{
                position: relative;
                left: 0vw;
                width:100% ;
            }
        }
    }
}