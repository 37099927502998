@import '../../../assets/constants/helpers/helpers.scss';

.entreprise-page{
    .header{
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 ;
        position: relative;
        background: linear-gradient(180deg, $transparentGreen 0%, $transparentBlack 100%);
        backdrop-filter: blur(250px);
        font-family: $font;
        .text{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 6%;
            .title{
                width: 70%;
                font-style: normal;
                font-weight: $extraBold ;
                font-size: 51px;
                line-height: 52px;
                color: $darckGray;
                text-align: left;
            }
            .sub-title{
                width: 70%;
                font-style: normal;
                color: $black ;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 51px;
            }
        }
        .image{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding:0;
            height: 100%;
            width: 50%;
            position: relative;
            right: 0;
            .man{
                position: absolute;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;  
            }       
        }
        .secend-text{
            height: 14px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 21px;
            line-height: 14px;
            color: $black ;
        }
        .message-love{
            position: absolute;
            bottom: 20% ;
            right:1%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 70px;
            animation-name: mouvment-text-v2;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $transparentBlack;
                border-radius: 8.20116px;
                height: 50px;
                color: $darckBlue ;
                position: relative;
                .member{
                    position: absolute;
                    top: -50%;
                    left: 50%;
                }
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }
            .img{
                display: block;
                margin-left: 20px;
                margin-right: 20px;
            }

        }
        .message-rating{
            position: absolute;
            right: 34%;
            top: 37%;
            animation-name: mouvment-text;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .top{
                display: flex;
                justify-content: space-between;
                padding: 5px 10px 5px 5px;
                .sub-text{
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 10px;
                    line-height: 8px;
                    color: $darckBlue ;
                }
            }
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $transparentBlack;
                border-radius: 8.20116px;
                height: 45px;
                color: $darckBlue ;
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }
        }
    }
    @media (max-width :700px) {
        .header{
            height: 350px;
            .text{
                .title{
                    font-size: 26px;
                    line-height: 26px;
                }
                .sub-title{
                    margin-top: 10px;
                }
            }
            .image{
                position: static;
                .man{
                    height: 72%;
                    width: 90%;  
                }       
            }
            .secend-text{
                width: 350px;
                bottom: 15%;
                left: 20px;
            }
            .message-love{
                .avi{
                    border-radius: 6px;
                    height: 40px;
                    .member{
                        top: -75%;
                    }
                }
                .img{
                    width: 60px;
                    height: 60px;
                }
            }
            .message-rating{
                right: 0%;
                top: 30%;
                .avi{
                    align-items: center;
                    border-radius: 6px;
                    height: 40px;
                }
            }
        }
    }
}