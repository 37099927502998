//colors
$green: #00AA91;
$yellow: #FFCB1A;
$blue: #2263F3;
$pink: #F5948F;
$white: #FFFFFF;
$whitev2:#F7F9FA;
$black: #000000;
$greenOrigin : rgb(12, 226, 12);
$green3 :#1CB222;
$red : #F12B2C;
$greenTransparent : rgb(160, 238, 160);

$gray : #C3C3C3 ;
$grayV2 :   #9FA2B4;
$grayV3 : #C5C7CD;
$darckGray :#3A3A3A ;
$lightGray : #F6F6F6 ;
$transparentGray : rgba(254, 253, 253, 0.3);
$darckBlue :#6D8399 ;
$lightBlue : #77bbff ;
$SemitransparentBlack :  #00000033 ;
$transparentBlack:  rgba(250, 250, 250, 0);
$transparentGreen : rgba(0, 170, 145, 0.2) ;
$danger : #db2828 ;
$transparentPink :rgba(245, 148, 143, 0.2) ;
$transparentBlue :rgba(34, 99, 243, 0.2) ;
$darck : #252733;
$darckV2 : #4B506D;

//font-weight
$bold: bold;
$extraBold : 800 ;
$semiBold: 600;
$medium: 500;
$regular: 400;
$light: 300;

//font-family
$font: 'Mulish', sans-serif;
