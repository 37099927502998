@import '../../../../assets/constants/helpers/helpers.scss';

.step-form{
    padding: 30px 73px;
    .group{
        justify-content: flex-start;
        width: 100%;
        label{
            color: $gray;
            font-size: 18px;
            font-style: normal;
            font-weight: $regular ;
            line-height: 20px;
            width: 100%;
            margin: 0 0 0.28571429rem;
            text-align: start;
            text-transform: none;
            display: block;
            select{
                height: 55px;
                width: 96%;
                border: 2px solid $gray;
                border-radius: 12px;
                text-align: start;
                padding: 0 20px;
                margin: 10px 0 40px 0;
                color: $gray;
                option{
                    margin-right: 20px;        
                }
            }
            .fullwidth{
                width: 90%;
            }
        }
    }
    .cart{
        .wrapper-cart{
            width: 48%;
            .recto,.verso{
                width: 100%;
                margin: 0;
            }
        }
    }
}