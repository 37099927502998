@import '../../../assets/constants/helpers/helpers.scss';

.entreprise-page{
    font-family: $font;
    .description{
        width: 100%;
        margin-top: 100px;
        .title{
            width: 100%;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;            
            .center{
                width: 390px;
                height: 35px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 31px;
                line-height: 35px;
                text-align: center;
                color: $black;
            }
            .shape{
                position: absolute;
                right: 0;
                fill: $pink;
            }
        }
        .detailes{
            width: 100%;
            display: flex;
            padding: 0 50px;
            justify-content: space-between;
            align-items: center;
            .wraper{
                width: 45%;
                text-align: justify;
                .text{
                    width: 827px;
                    height: 155px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 20px;
                    line-height: 31px;
                    text-align: center;
                    color: $black;
                }
            }
        }
    }
    @media (max-width :700px) {
        .description{
            .detailes{
                flex-direction: column;
                .wraper{
                    width: 115%;
                    text-align: justify;
                    margin-bottom: 20px;
                    .text{
                        font-weight: $semiBold;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .desc{
        margin-top: 100px;
        width: 100%;
        height: 300px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding: 10px 100px ;
        .shape{
            fill: $green;
            position: absolute;
            left: 0;
        }
        .title{
            width: 390px;
            height: 105px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 31px;
            line-height: 35px;
            color: $black;
            text-align: left;
        }
        .text{
            width: 495px;
            height: 93px;
            font-style: normal;
            font-weight: $regular;
            font-size: 25px;
            line-height: 31px;
            color: $black;
            text-align: left;
        }
    }
    @media (max-width :700px) {
        .desc{
            padding: 10px 10px ;
            .shape{
                bottom: -55px;
            }
            .wrapper{
                width: 100%;
                .title{
                    width: 400px;
                    font-size: 22px;
                    line-height: 25px;
                    height: 70px;
                }
                .text{
                    width: 360px;
                    font-size: 19px;
                    line-height: 25px;
                }                
            }
        }
    }
}