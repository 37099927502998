@import "../../../../assets/constants/helpers/helpers.scss";

.nav-client{
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    position: fixed;
    width: calc(100% - 240px);
    left: 240px;
    z-index: 100;
    padding: 0 20px;
    // box-shadow: 0 1px 1px $SemitransparentBlack;
    transition: all 0.5s ease;
    // background-color: $lightGray;
    font-family: $font;

    .title{
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: $semiBold;
    }
    .options{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 33%;
        box-sizing: border-box;
        height: 80px;
        padding: 20px;
        .icons{
            display: flex;
            width: 20%;
            justify-content: space-between;
            padding-top: 5px;
            &::after{
                content: "";
                width: 3px;
                height: 30px;
                background-color:  $gray;
            }
            .icon{
                color: $gray;
                height:25px;
                width: 25px;
            }
        }
        .info{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 0 10px;
            align-items: center;
            width: 80%;

            .name{
                color: $black;
                font-size: 17px;
                font-weight: $bold;
                overflow: hidden;
                margin-left: 1vw;
                text-overflow: ellipsis;
            }
            .containet-info{
                width: 140px;
                position: relative;

                .image-name{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    cursor: pointer;

                    img{
                        width: 40px;
                        height: 40px;
                    }
                    &:hover{
                        ~.dropdown{
                            opacity: 1;
                            transform: translateX(0);
                            

                        }
                    }
                }
                .btn-logout{
                    border: 1px solid $darckGray;
                    border-radius: 4px;
                    color: $darckGray;
                    background-color: $white;
                    cursor: pointer;
                    width: 90%;
                    z-index: 99;

                }
                .dropdown{
                    opacity: 0;
                    transition: all 100ms ease-in-out;
                    position: absolute;
                    right: 0px;
                    width: 100px;
                    height: 60px;
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    transform: translateY(-60%);
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: $white;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    &:hover{
                        opacity: 1;
                        transform: translateX(0);

                    }
                    .btn{

                        width: 100%;
                        height: 30px;
                        background-color: $white;
                        border: none;
                        cursor: pointer;
                        
                        &:hover{
                            background-color: $lightGray;
                        }
                    }
                }
            }
            /*
            .image{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 3px solid $darckBlue;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                
                img{
                    width: 95%;
                    height: 95%;
                    border-radius: 50%;
                }
            }
            */
        }
    }
}

@media (max-width: 936px) {
    .nav-client{
        .options{
            width: 43%;
            .icons{
                margin-right: 2vw;
            }
            .info{
                width: 100%;
                position: relative;
                right: 2vw;
                margin-left: 2vw;
                .name{
                    width: 10vw;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .nav-client{ 
        width: 100%;
        left: 0px;
    }    
}