@import '../../assets/constants/helpers/helpers.scss';

.check-login-2{
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $font;
     .bg{
        height: calc(100% - 15vh);
        width: 100%;
        position: absolute;
        top: 17vh;
        background-color: #000000;
        opacity: 0.2;
     }
    .header{
        z-index: 1000;
        .text{
            width: 60%;
            min-height: 85vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 80px;
            .title{
                width: 70%;
                font-style: normal;
                font-weight: $extraBold;
                font-size: 51px;
                line-height: 52px;
                color: $white;
                text-align: left;
            }
            .sub-title{
                width: 70%;
                font-style: normal;
                color: $white;
                text-align: left;
                margin-top: 20px;
                width: 491px;
                min-height: 62px;
                font-weight: $regular;
                font-size: 25px;
                line-height: 31px;
                color: $white;
            }
            .buttons{
                display: flex;
                width: 73%;
                margin: 15px;
                .btn-entreprise{
                    width: 179px;
                    height: 47px;
                    background: $green;
                    border-radius: 26px;
                    display: flex;
                    justify-content:  center;
                    align-items: center;
                    border: none;
                    margin: 2px;
                    font-style: normal;
                    font-weight: $medium;
                    font-size: 18px;
                    line-height: 23px;
                    text-align: center;
                    color: $white;
                    cursor: pointer;
                    &:hover{
                        opacity: 0.8;
                    }
                    &.sc{
                            background: transparent;
                            border: 1px solid $white;
                        &:hover{
                            opacity: 0.8;
                            background: $green;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:700px){
        .header{
            .text{
                width: 100%;
                padding: 20px;
                .title{
                    width: 100%;
                    font-weight: $regular;
                    font-size: 32px;
                }
                .sub-title{
                    width: 100%;
                    margin: 20px 0;
                }
                .buttons{
                    width: 100%;
                }
            }
        }
    }
}