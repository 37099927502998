@import '../../assets/constants/helpers/helpers.scss';
.propos-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    .vectorUp{
        z-index: 5000;
        &:hover{
            cursor: pointer;
        }
    }
    .vectorDown{
        z-index: 5000;
        height: 35px;
        &:hover{
            cursor: pointer;
        }
    }
}