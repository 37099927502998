@import '../../assets/constants/helpers/helpers.scss';

.profile-page{
    background-color: $whitev2;
    width: 100%;
    * {
        box-sizing: border-box;
    }
    .sidebarinf700{
        display: none;
    }
    .menu{
        display: none;
    }
    .profile-section{
        position: relative;
        min-height: 100vh;
        width: calc(100% - 240px);
        left: 240px;
        transition: all 0.5s ease;
        .profile-content{
            box-sizing: border-box;
            position: relative;
            padding-top: 104px;
            .profile-content-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                .copyright{
                    margin: 10px;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .profile-page{
        .sidebarsup700{
            display: none;
        }
        .menu{
            display: block;
            background-color: $blue;
            width: 100%;
        }
        .profile-section{
            width: 100%;
            left: 0px;
        }
    }
}