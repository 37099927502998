@import '../../../assets/constants/helpers/helpers.scss';

.checkbox.toggle{
    position: relative;
    width: 60px;
    height: 25px;
    -webkit-appearance: none;
    background: $danger;
    outline: none;
    border-radius: 20px;
    box-shadow: inset $transparentBlack;
    transition: .5s;
    &.orange{
        background: $yellow ;
    }
    &.kiwi{
        background: $green ;
    }
    // &:checked{
    // }
}
.checkbox.toggle::before{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: $white;
    transform: scale(0.95);
    box-shadow: $transparentBlack;
    transition: .5s;
    z-index: 22;
}
.checkbox.toggle:checked::before{
    left: 35px;
}
.checkbox.toggle::after{
    content: 'NO';
    font-family: $font;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    top: 0;
    left: 30px;
    transform: scale(0.95);
    transition: .5s;
    z-index: 22;
}
.checkbox.toggle:checked::after{
    content: 'YES';
    left: 10px;
}