@import '../../../../assets/constants/helpers/helpers.scss';

.step-form-moto{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
    width: 100%;
    min-height: 350px;
    font-family: $font;
    .group{
        display: flex;
        width: 100%;
        &.grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            label{
                width: 95%;
                select{
                    width: 100%;
                }
            }
        }
        label{
            color: $gray;
            font-size: 18px;
            font-style: normal;
            font-weight: $regular ;
            line-height: 20px;
            width: 100%;
            margin: 0 0 0.28571429rem;
            text-align: start;
            text-transform: none;
            display: block;
            width: 400px;
            select, input[type=number]{
                height: 55px;
                border: 2px solid $gray;
                border-radius: 12px;
                text-align: start;
                padding: 0 20px;
                margin: 10px 0 40px 0;
                color: $gray;
                option{
                    margin-right: 20px;        
                }
            }
            input[type=number]:focus{
                outline: none;
                border-color: $lightBlue;
            }
            .fullwidth{
                width: 90%;
            }
        }
        .wrapper{
            box-sizing: border-box;
            width: 580px;
            height: 152px;
            background: $lightGray;
            border: 1px solid $blue;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 80px 0 20px;
            position: relative;
            .container-logo{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $blue;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .price{
                font-style: normal;
                font-weight: $extraBold;
                font-size: 23px;
                line-height: 26px;
                color: $danger;
            }
            .icon{
                position: absolute;
                top: 10px;
                right: 10px;
            }
            .title-container{
                text-align: start;
                .h2{
                    font-style: normal;
                    font-weight: $extraBold;
                    font-size: 23px;
                    line-height: 26px;
                    color: $black;
                }
                .name{
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 17px;
                    line-height: 26px;
                    color: $black;
                }
            }

        }
    }
}
.step-form{
    .cart{
        .wrapper-cart{
            .recto, .verso{
                .inputFileExist{
                    padding-top: 8vh;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.8;
                    color: white;
                    font-size: 1.2vw;
                    overflow: hidden;
                    position: relative;
                    .fileExist{
                        cursor: pointer;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0px;
                        left: 0px;
                        opacity: 0;
                    }
                }
                label{
                    position: relative;
                    top: 4vh;
                    display: inline-block;
                    .title1{
                        display: block;
                    }
                    .title2{
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
    }
}
