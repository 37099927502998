@import '../../../../assets/constants/helpers/helpers.scss';

.step-form-ac{
    font-family: $font;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    min-height: 345px;
    width: 95%;
    .options-ac{
        width: 100%;
        display: flex;
        padding: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .option-ac{
            width: calc(30%);
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $danger;
            border-radius: 20px 20px 0px 0px;
            overflow: hidden;
            color: $danger;
            padding-bottom: 0 ;
            background-color: $white;
            &.orange{
                color: $yellow ;
                border: 1px solid $yellow ;
            }
            &.kiwi{
                color: $green;
                border: 1px solid $green ;
            }
            .box-v2{
                margin: 20px 0 0 0;
                width: 85%;
                .option-name{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    h2{
                        font-style: normal;
                        font-weight: $extraBold;
                        font-size: 30px;
                        line-height: 38px;
                    }
                }
                .title{
                    font-style: normal;
                    font-weight: $extraBold;
                    font-size: 21px;
                    line-height: 26px;
                    width: 100%;
                    text-align: start;
                }
                .ul{
                   
                    .li{ 
                        position: relative;
                    ::after{
                        left: 0;
                        top: 10px;
                        position: absolute;
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: black;

                    }
                        font-style: normal;
                        font-weight: $medium;
                        font-size: 17px;
                        line-height: 21px;
                        text-align: start;
                        padding-left: 30px;
                        color: $darckGray;
                        .price{
                            font-style: normal;
                            font-weight: $extraBold;
                            font-size: 20px;
                            line-height: 25px;
                        }
                    }
                }
                &.br{
                    border-bottom: 2px dashed $gray;
                }
            }
            .box-green{
            width: 100%;     
            display: flex;  
            flex-direction: column;         
            align-items: center;
            justify-content: center;
            padding: 20px 0 0 0;
            background-color: $lightGray;
            .wrapper{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                justify-content: flex-start;
                justify-items: flex-start;
                width: 90%;
                padding: 10px 0;
                min-height: 90px;
                    .title{
                        font-style: normal;
                        font-weight: $extraBold;
                        font-size: 21px;
                        line-height: 26px;
                        text-align: start;
                    }
                    .opt{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin: 10px 0 0 0 ;
                        .label{
                            font-style: normal;
                            font-weight: $extraBold;
                            font-size: 17px;
                            line-height: 21px;
                            color: $darckGray;
                            margin-left: 10px;
                        }
                        .price{
                            font-style: normal;
                            font-weight: $extraBold;
                            font-size: 26px;
                            line-height: 33px;
                            color: $darckGray;
                        }
                    }
                    &.br{
                        border-bottom: 2px dashed $darckGray;
                    }
                }
                
            }
            .commander{
                background-color: $danger;
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: $extraBold;
                font-size: 22px;
                line-height: 28px;
                color: $white;
                margin-bottom: 0;
                cursor: pointer;
    
                &.orange-b{
                    background-color: $yellow
                }
                &.kiwi-b{
                    background-color: $green;
                }
               }
        }
        @media (max-width: 700px){
            flex-direction: column;
            .option-ac{
                width: 95%;
                margin: 10px 0;
            }
        }
    }
    .group-3{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    .option{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 60px;
        justify-content: space-between;
        margin: 20px 0;
        .text{
            font-style: normal;
            font-weight: $extraBold;
            font-size: 21px;
            line-height: 26px;
            color: $darckGray;
        }
        .icon{
            display: block;
        }
    }
    .wrapper-text{
        width: 100%;
        text-align: start;
        margin: 20px 0;
        .title{
            font-style: normal;
            font-weight: $extraBold;
            font-size: 25px;
            line-height: 151.8%;
            color: $darckGray;
        }
        .text{
            font-style: normal;
            font-weight: $extraBold;
            font-size: 21px;
            line-height: 151.8%;
            color: $darckGray;
        }
    }
    .wrapper-text-logo{
        width: 100%;
        margin: 20px 0 ;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon{
            display: block;
        }
        .text{
            font-style: normal;
            font-weight: $extraBold;
            font-size: 25px;
            line-height: 151.8%;
            margin-left: 15px;
            color: $darckGray;
        }
    }
    .title-mode{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $gray;

        .icon-container{

            width: 40px;
            height: 40px;
            .icon{
                width: 40px;
                height: 40px;
                
            }

        }
        .text{
            font-style: normal;
            font-weight: 800;
            font-size: 25px;
            line-height: 151.8%;
            margin-left: 20px;
        }
    }
    .contianer-list{
        width: 100%;
        margin: 20px 0;
        position: relative;
        .list{
            width: 100%;
            display: flex;
            align-items: center;
            border: none;
            border-bottom: 2px solid $darckGray;
            padding: 10px ;
            color: $gray;
            cursor: pointer;
            position: relative;
            &.open{
                .drop{
                    transition: all 400ms ease-in-out;
                    transform: rotateX(180deg);
                }
                .title{
                    color: $green;
                }
            }
            .title{
                font-style: normal;
                font-weight: $regular;
                font-size: 25px;
                line-height: 151.8%;
                margin-left: 30px; 
            }
            .drop{
                position: absolute;
                right: 20px;
                top: 25px;
                transition: all 400ms ease-in-out;
            }
            
        }
        .drop-down-toggle{
        width: 100%;
        height: 0px;
        // opacity: 0;
        overflow: hidden;
        transition: all 1s ease-in-out;
        &.open{
            display: block;
            height: 120px;
            opacity: 1;
            transition: all 1s ease-in-out;
            overflow: hidden;
        }

        }
        .text{
            background-color: black;
            color: $white;
            width: 100%;
            height: 50px;
            opacity: 1;
        }
    }
    .wrapper-text{
        width: 100%;
        padding: 20px 10px;
        font-style: normal;
        font-weight: $light;
        font-size: 18px;
        line-height: 151.8%;
        text-align: start;
    }
    .wrapper-conditions{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .condition{
            margin: 10px;
            .label{
                font-style: normal;
                font-weight: $medium;
                font-size: 18px;
                line-height: 151.8%;
            }
        }
    }
}