@import "../../../../assets/constants/helpers/helpers.scss";

.contact-page{
    width: 94%;
    min-height: 80vh;
    background-color: $white;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: $font;
    justify-content: center;
    align-items: flex-start;

    .alert{
        margin-left: 100px;
        margin-bottom: 20px;
    }
    form{
        margin-left: 100px;
        width: 60%;
    }
    .field-1{
        width: 100%;
    }
    input,textarea{
        background-color: $whitev2;  
        border-color: $whitev2;
        font-weight: $semiBold;
        font-size: 15px;
        line-height: 18px;
        color: $grayV2;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    label{
        font-family: $font;
        font-style: normal;
        font-weight: $regular;
        font-size: 15px;
        line-height: 18px;
        color: $black;
        width: 100%;
    }
    textarea{
        width: 100%;
        padding: 20px;
        color: $black;
        &:focus{
            color: $black;
        border-color: $lightBlue;
        border-radius:12px;
        background: $white;
        box-shadow: 0 0 0 0 $SemitransparentBlack inset;
        }

    }
}