@import '../../assets/constants/helpers/helpers.scss';
.stepper{

    min-width: 850px ;
    height: 60px;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    background-color: $lightGray;
    box-sizing: content-box;
    padding: 10px;
    border-radius: 10px;

    .step{
            width: auto;
            height:100% ;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
        .number{
            font-family: $font;
            height: 100%;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $white;
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            color: $gray;
            margin-right: 10px;
        }
        .label{
            font-family: $font;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: $gray;

        }
        &:after{
                width: 80px;
                height: 1px;
                position: absolute;
                border-bottom: 2px  dashed $gray;
                content: "";
                right: -100px;
                font-family: $font;
                border-radius: 7px;
        }
        &.currentStep{
            .number{
                background-color: $blue;
                color: $white;
            }
            .label{
                color: $blue;
            }
        }
        &.finalStep{
                &:after{
                    border: none;
                }
        }
    }

}

@media (min-width: 700px) and (max-width: 936px) {
    .stepper{
        min-width: 90vw;
        .step{
            &:after{
                display: none;
            }
        }
    }
}

@media (max-width: 700px) {
    .stepper{
        min-width: 85vw;
        height: 30px;
        .step{
            .number{
                width: 20px;
                height: 20px;
                font-size: 16px;
            }
            .label{
                font-size: 16px;
            }
            &:after{
                display: none;
            }
        }
    }
}