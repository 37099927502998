@import '../../assets/constants/helpers/helpers.scss';

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    padding: 58px 0;
    min-height: 70vh;
    font-family: $font;
.avis-conatine{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 70px;            
        align-items: center;
        position: relative;
        .vector{
            position: absolute;
            bottom: 0;
            left: 50%;
        }
        .vectorDown{
            z-index: 5000;
            height: 35px;
            &:hover{
                cursor: pointer;
            }
        }
        .messages{
            width: 460px;
            height: 400px;
            position: relative;
            .msg{
                width: 464.71px;
                height: 188px;
                border-radius: 21px;
                text-align: left;
                padding: 15px 30px 20px 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                background: $transparentGray;
                color: $SemitransparentBlack;
                &.msg-center{
                    position: absolute;
                    background: $lightGray;
                    top: 25%;
                    left: -1.5%;
                    color: $black;
                    width: 103%;
                }
                .txt{
                    width: 390.11px;
                    height: 96px;
                    font-style: normal;
                    font-weight: $regular ;
                    font-size: 16px;
                    line-height: 21px;
                }
                .auther{
                    width:100%;
                    height: 27px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 2px 5px;
                    .name{
                        height: 27px;
                        font-style: normal;
                        font-weight: $semiBold;
                        font-size: 15px;
                        line-height: 15px;
                    }
                }
            }
            .member{
                position: absolute;
                left: -40px;
                top: 20%;
                z-index: +2;
            }
        }
        .wrapper-text{
            .title{
                width: 396px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 45px;
                line-height: 55px;
                color: $white;
                text-align: left;
            }
            .text{
                width: 396px;
                height: 155px;
                font-style: normal;
                font-weight: $regular;
                font-size: 26px;
                line-height: 35px;
                color: $white ;
                text-align: left;
                margin-top: 15px;
            }
        }
} 
.contact{
        width: 90%;
        height: 400px ;
        border-bottom: 2px solid $transparentGray;
        margin-top: 70px;
        .wrapper{
            width: 100%;
            height: 350px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: $white ;
            border-radius: 39px;
            padding-top: 30px;
            .title{
                width: 587px;
                height: 132px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 33px;
                line-height: 33px;
                text-align: center;
                color: $darckGray;
                .sub-title{
                    width: 587px;
                    height: 132px;
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 23px;
                    line-height: 33px;
                    text-align: center;
                    color: $darckGray;
                }
            }
            .text{
                width: 587px;
                height: 92px;
                font-style: normal;
                font-weight: $medium ;
                font-size: 17px;
                line-height: 23px;
                text-align: center;
                color: $darckGray;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .sub-text{
                    margin-top: 25px;
                }
            }
            .btn{
                width: 261px;
                height: 47px;
                background: $blue;
                border-radius: 26px;
                margin: 40px 0;
                border: none;
                font-style: normal;
                font-weight: $medium ;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                color: $white ;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
}
.options{
        width: 95%;
        height: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between; 
        margin-top: 30px;  
        .links{
            width: 25%;
            height: 100%;
            display:flex ;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            color: $white ;
            .link{
                cursor: pointer;
                font-style: normal;
                font-size: 20px;
                line-height: 31px;
                font-weight: $regular;
                h3{
                    font-weight: $semiBold;
                }
                &:hover{
                    color: $lightBlue;
                }              
            }
        }
    }
}

@media (min-width: 700px) and (max-width: 1124px) {
    .footer{
        .avis-conatine{
            .vector{
                display: none;
            }
            .messages{
                margin-right: 2vw;
                width: 45vw;
                .member{
                    width: 60px;
                }
                .msg-top, .msg{
                    width: 45vw;
                    .txt{
                        font-size: 16px;
                        width: inherit;
                    }
                }
            }
            .wrapper-text{
                .title{
                    font-size: 25px;
                }
                .text{
                    font-size: 22px;
                    width: 40vw;
                }
            }
        }
    }
}

@media (max-width :700px) {
    .footer{
        .avis-conatine{
            flex-direction: column-reverse;
            padding: 10px 5px;            
            .vector{
                display: none;
            }
            .messages{
                margin-left: 20px;
                width: 85%;
                .msg{
                    width:100%;
                    height: 200px;
                    padding: 20px 5px 20px 38px;
                    .txt{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .wrapper-text{
                .title{
                    width:100%;
                    font-size: 26px;
                    line-height: 22px;
                    margin-left: 15px;
                }
                .text{
                    line-height: 25px;
                    font-size: 25px;
                    margin-left: 15px;
                }
            }
        } 
        .contact{
            .wrapper{
                .title{
                    .sub-title{
                        margin-top: 10px;
                    }
                }
                .text{
                    width: 90%;
                }
            }
        }
        .options{
            height: auto;
            flex-direction: column;
            .links{
                width: 80%;
                margin-bottom: 20px;
                .link{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}
