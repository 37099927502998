@import '../../assets/constants/helpers/helpers.scss';

.nav-bar{
    width: 100%;
    height: 17vh;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 0.4px 3px $transparentBlack;
    font-family: $font;
    background: $white;
    &.transparent{
        background: linear-gradient(180deg, $transparentBlack 0%, $transparentBlack 100%);
        backdrop-filter: blur(125px);
        box-shadow: 1px -1px 4px $SemitransparentBlack;
    }  
    .sub-nav{
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        color: $darckGray;
        .info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
            .item{
                display: flex;
                align-items: center;
                justify-content: center;
                .icon{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
                .text{
                    margin-left: 5px;
                }
            }
        }
        .action{
            display: flex;
            justify-content: space-between;
            width: 25%;
            align-items: center;
            .second-link{
                font-style: normal;
                font-weight: $regular;
                font-size: 16px;
                line-height: 20px;     
                width:auto;          
                height: 20px;
                cursor: pointer;
                transition: all 1s ease-in-out;
                &:hover{
                    text-decoration: underline;
                }
            }
            .primery-link{
                width: 135px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
                border: 1px solid $SemitransparentBlack;
                color: $black;
                border-radius: 4px;
                transition: all 300ms ease-in-out ;
                cursor: pointer;
                transform: translateY(4px);
                &:hover{
                    transform: translateY(-1px);
                    background-color: $blue;
                    color: $white;
                    border: none;
                }
            }
            .containet-info{
                width: 140px;
                position: relative;
                span{
                    width: 10vw;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 1vw;
                }

                .image-name{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    cursor: pointer;

                    img{
                        width: 40px;
                        height: 40px;
                    }
                    &:hover{
                        ~.dropdown{
                            opacity: 1;
                            transform: translateX(0);
                            

                        }
                    }
                }
                .btn-logout{
                    border: 1px solid $darckGray;
                    border-radius: 4px;
                    color: $darckGray;
                    background-color: $white;
                    cursor: pointer;
                    width: 90%;
                    z-index: 99;

                }
                .dropdown{
                    opacity: 0;
                    transition: all 100ms ease-in-out;
                    position: absolute;
                    width: 100%;
                    height: 60px;
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    transform: translateY(-60%);
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: $white;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    &:hover{
                        opacity: 1;
                        transform: translateX(0);

                    }
                    .btn{

                        width: 100%;
                        height: 30px;
                        background-color: $white;
                        border: none;
                        cursor: pointer;
                        
                        &:hover{
                            background-color: $lightGray;
                        }
                    }
                }
            }
        }
    }
    .primery-nav{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 50px 0 50px;
        .left{
            display: flex;
            align-items: center;
            width: 50%;
            height: 100%;
            justify-content: space-between;
            .logo{
                .silyassuer{
                    width: 176.54px;
                    height: 45px;}
            }
            .mode{
                display: flex;
                width: 55%;
                background-color: $lightGray;
                height: 45px;
                justify-content: space-between;
                border-radius: 50px;
                .option1{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $darckGray;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                    }
                    &.active{
                        background-color: $green;
                        border-radius: 50px;
                        color: $white;
                    }
                }
                .activeEnt{
                    background-color: $green;
                    border-radius: 50px;
                    color: $white;
                }   
                .option2{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $darckGray;
                    cursor: pointer;
                    &.active{
                        background-color: $pink;
                        border-radius: 50px;
                        color: $white;
                    }
                }
                .activeInd{
                    background-color: $pink;
                    border-radius: 50px;
                    color: $white;
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            width: 50%;
            height: 100%;
            justify-content: flex-end;
            .link-1{
                margin: 0 20px;
                font-style: normal;
                font-weight: $medium;
                font-size: 18px;
                line-height: 23px;
                cursor: pointer;
                position: relative;
                z-index: 1;
                transition: all 100ms ease-in-out;
                color: $darckGray;
                &:hover{
                    color: $blue ;
                }
                &.active{
                    color: $blue;
                }
            }
            .search{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
} 
   @media (max-width :700px) {
    .nav-bar{
        height: 200px;
        .sub-nav{
            flex-direction: column;
            height: 80px;
            padding: 0;
            .info{
                align-items: center;
                width: 100%;
                padding: 10px 7px;                        
                .item{
                    .text{
                        font-size: 13px;
                    }
                }
            }
            .action{
                width: 100%;
                padding: 5px 20px;
            }
        }
        .primery-nav{
            flex-direction: column;
            padding: 10px 4px;        
            .left{
                width: 100%;
                margin: 10px 0;
                .logo{
                    .silyassuer{
                        width: 110px;
                    }
                }
                .mode{
                    display: flex;
                    width: 65%;
                    background-color: $lightGray;
                    height: 45px;
                    justify-content: space-between;
                    border-radius: 50px;
                    .option1{
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $darckGray;
                        cursor: pointer;
                        a{
                            text-decoration: none;
                        }
                        &.active{
                            background-color: $green;
                            border-radius: 50px;
                            color: $white;
                        }
                    }   
                    .option2{
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $darckGray;
                        cursor: pointer;
                        &.active{
                            background-color: $pink;
                            border-radius: 50px;
                            color: $white;
                        }
                        }
                }
            }
            .right{
                width: 100%;
                justify-content: space-between;
                .link-1{
                    margin: 0 5px;
                    font-size: 14px;
                    word-wrap: normal;
                }
                .search{
                    width: 20px;
                    height: 20px;
                }
            }
        }
   } 
}

@media (min-width: 936px) and (max-width: 1224px) {
    .nav-bar{
        .sub-nav{
            .info{
                .item{
                    .text{
                        font-size: 16px;
                        margin-right: 3vw;
                    }
                }
            }    
            .action{
                width: 30vw;
                .second-link{
                    p{
                        width: 16vw;
                    }
                } 
            }
        }
        .primery-nav{
            font-size: 16px;
            .left{
                .logo{
                    .silyassuer{
                        width: 150px;
                    }
                }
            }
            .right{
                .link-1{
                    font-size: 16px;
                    margin-right: 1vw;
                    margin-left: 0px;
                }
            }  
        }
    }
}

@media (min-width: 700px) and (max-width: 936px) {
    .nav-bar{
        .sub-nav{
            .info{
                .item{
                    .text{
                        font-size: 14px;
                        margin-right: 3vw;
                    }
                }
            }    
            .action{
                width: 30vw;
                font-size: 14px;
                .second-link{
                    p{
                        width: 20vw;
                        font-size: 14px;
                    }
                }
                .containet-info{
                    span{
                        width: 5vw;
                        margin-left: 1vw;
                    }
                }    
            }
        }
        .primery-nav{
            font-size: 14px;
            .left{
                .logo{
                    .silyassuer{
                        width: 100px;
                    }
                }
            }
            .right{
                .link-1{
                    font-size: 14px;
                    margin-right: 1vw;
                    margin-left: 0px;
                }
            }  
        }
    }
}

@media (max-width: 700px) {
    .nav-bar{
        .sub-nav{
            .info{
                .item{
                    .text{
                        margin-right: 3vw;
                    }
                }
            }    
            .action{
                justify-content: center;
                width: 30vw;
                .second-link{
                    p{
                        width: 30vw;
                    }
                }
                .containet-info{ 
                    img{
                        margin-left: 2vw;
                    }
                    span{
                        width: 20vw;
                        margin-left: 2vw;
                    }
                }    
            }
        }
        .primery-nav{
            .left{
                .logo{
                    .silyassuer{
                        width: 100px;
                    }
                }
            }
            .right{
                .link-1{
                    margin-right: 1vw;
                    margin-left: 0px;
                }
            }  
        }
    }
}