@import '../../../assets/constants/helpers/helpers.scss';

.entreprise-page{
    .insurances-links{
        width: 100%;
        height: 170px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0;
        background-color: $green;
        transition: all 200ms ease-in-out;
        font-family: $font;
        &.fixed{
            position : fixed ;
            z-index: 1 ;
            height: 140px;
            .mode{
                width: 95%;
                height: 45px;
                background: $yellow;
                transition: all 1s ease-in-out ;                        
            }
            .wrapper-links{
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .link{
                    width: calc(100%/6);
                    height: 80px;
                    border: 2px solid $transparentBlack;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 6px 0 ;
                    cursor: pointer;
                    &:hover{
                        border: 2px solid $white;
                    }
                    .name{
                        margin-top: 10px;
                        width: 100%;
                        height: 50%;
                        font-style: normal;
                        font-weight: $regular ;
                        font-size: 16px;
                        line-height: 23px;
                        text-align: center;
                        color: $white;
                        margin-top: auto;
                    }
                }
                :nth-child(1){
                    width: 13%;
                }
                :nth-child(2){
                    width: 13%;
                }
                :nth-child(3){
                    width: 17%;
                }
            }
        }
        .mode{
            display: flex;
            width: 85%;
            background-color: $lightGray;
            justify-content: space-between;
            border-radius: 50px;
            height: 60px;
            background: $yellow;
            transition: all 1s ease-in-out ;                        
            .option{
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 20px;
                line-height: 14px;
                text-align: center;
                border: none;
                background: transparent;
                color: $black;
                cursor: pointer;
                a{
                    text-decoration: none;
                }
                &.active{
                    background: $white;
                    box-shadow: 10px 4px 24px $transparentBlack;
                    border-radius: 53.5px;
                }
            }   
        }
        .wrapper-links{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .link{
                width: 13%;
                height: 100px;
                border: 2px solid $transparentBlack;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 6px 0 ;
                cursor: pointer;
                &:hover{
                    border: 2px solid $white;
                }
                .name{
                    width: 100%;
                    height: 50%;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 16px;
                    line-height: 23px;
                    text-align: center;
                    color: $white;
                    margin-top: auto;
                }
                .icon{
                    width: 40px;
                    height: 40px;
                    margin-bottom: 5px;
                }
            }
        }
    }
    @media (max-width :700px) {
        .insurances-links{
            height: 180px;
            position: fixed;
            bottom: 0;
            z-index: 10;
            &.fixed{
                height: 180px;
                bottom: 0;
                .mode{
                    height: 60px;
                    .option{
                        font-size: 14px;
                    }
                }
                .wrapper-links{
                    margin: 3px 0;
                    display: grid;
                    grid-template-columns:repeat(6,33%);
                    grid-template-rows: repeat(1,1fr);
                    grid-gap: 2px;
                    overflow: auto;
                    .link{
                        width: 100%;
                        height: 100%;
                        .name{
                            width: 100%;
                            height: 100%;
                            font-size: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                        }
                    }
                }
            }
            .mode{
                width: 95%;
                height: 60px;
                .option{
                    font-weight: $regular;
                    font-size: 14px;
                }   
            }
            .wrapper-links{
                margin: 3px 0;
                display: grid;
                grid-template-columns:repeat(6,33%);
                grid-template-rows: repeat(1,1fr);
                grid-gap: 2px;
                overflow: auto;
                .link{
                    width: 100%;
                    height: 100%;
                    .name{
                        width: 100%;
                        height: 100%;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}