@import '../../assets/constants/helpers/helpers.scss';

.groupes{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around; 
    margin-top: 30px; 
    background-color: $blue; 
    padding: 20px;
    min-height: 200px;
    .links{
        width: auto;
        height: 100%;
        display:flex ;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        color: $white ;
        .link{
            cursor: pointer;
            font-style: normal;
            font-size: 20px;
            line-height: 31px;
            font-weight: $regular;
            h3{
                font-weight: $semiBold;
            }
            &:hover{
                color: $lightBlue;
            }              
        }
        .copyright{
            font-size: 13px;
            position: relative;
            left: 40px;
        }
    }
}

@media (max-width :700px) {
    .groupes{
        height: auto;
        flex-direction: column;
        .links{
            width: 80%;
            margin-bottom: 20px;
            .link{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
