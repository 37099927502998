@import "../../../../assets/constants/helpers/helpers.scss";

.sidebar{
    box-sizing: border-box;
    position: fixed;
    height: 100%;
    width: 240px;
    background: $blue;
    transition: all 0.5s ease;
    font-family: $font;

    .logo{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon{
            font-size: 28px;
            font-weight: $medium;
            color: $white;
            min-width: 60px;
            text-align: center;
        }
        .logo-name{
            color: $white;
            font-size: 24px;
            font-weight: $medium;
        }
    }
    .nav-links{
        margin-top: 50px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        .link-container{
            position: relative;
            list-style: none;
            height: 56px;
            display: flex;
            
            .link{
                height: 100%;
                width: 85%;
                display: flex;
                align-items: center;
                text-decoration: none;
                transition: all 0.4s ease;
                background: $blue;
                font-family: $font;
                border: none;
                cursor: pointer;
                border-radius: 6px;
                color: $white;
                transition: all 300ms ease-in-out;
                &:hover{
                    background-color: $white;
                    color: $darckGray;
                    svg{
                        fill-opacity: 0;
                        
                        path{
                            stroke: $darckGray;
                        }
                    }
                }
                &.active{
                    background-color: $white;
                    color: $darckGray;
                    svg{
                        fill-opacity: 0;
                        
                        path{
                            stroke: $darckGray;
                        }
                    }
                }

                .icon{
                    min-width: 60px;
                    text-align: center;
                    font-size: 18px;
                    color: $white;
                    path{

                        transition: all 500ms ease-in-out;
                    }

                 
                }
                .link-name{
                    font-style: normal;
                    font-size: 16px;
                    font-weight: $semiBold;
                    white-space: nowrap;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                }
            }
        }
        .log-out{
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
}

@media (max-width: 700px) {
    .sidebar{
        width: 100%;
        .nav-links{
           .link-container{
                justify-content: center;
                .link{
                    justify-content: center;
                    .icon{
                        display: none;
                    }
                }
           }
        }
    }
}    