@import '../../../assets/constants/helpers/helpers.scss';

.home-page{
    .description{
        width: 100%;
        margin-top: 100px;
        .title{
            width: 100%;
            height: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;            
            .center{
                width: 390px;
                height: 35px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 31px;
                line-height: 35px;
                text-align: center;
                color: $black;
            }
            .shape{
                position: absolute;
                right: 0;
                fill: $pink;
            }
        }
        .detailes{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .wraper{
                width: 60%;
                text-align: center;
                .text{
                    width: 827px;
                    height: 155px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 22px;
                    line-height: 31px;
                    text-align: center;
                    color: $black;
                }
            }
        }
        @media (max-width : 700px){
            .detailes{
                .wraper{
                    width: 85%;
                }
            }
        }
    }
}