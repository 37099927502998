@import "../../../../assets/constants/helpers/helpers.scss";

.overview-page{
    width: 94%;
    min-height: 80vh;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: $font;
    .containet-info{
        width: 500px;
        position: relative;
        left: 30px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .image-name{
            display: flex;
            align-items: center;
            cursor: pointer;

            img{
                width: 50px;
                height: 50px;
            }
            &:hover{
                ~.dropdown{
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
        .info{
            display: flex;
            flex-direction: row;
            padding: 0 10px;
            align-items: center;
            width: 80%;
    
            .name{
                color: #252733;
                font-size: 17px;
                font-weight: $bold;
                overflow: hidden;
                margin-left: 1vw;
                text-overflow: ellipsis;
            }
        }    
    }
    .blocks-data{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 85%;
        position: relative;
        left: 70px;
        margin-bottom: 40px;
        .blockdata{
            width: 180px;
            height: 130px;
            border-radius: 1%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .content{
                font-weight: $extraBold;
                font-family: $font !important;
            }
            .content, .title{
                color: white;
            }
        }
    }
    .blocks-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        left: 30px;
        .blockInfo{
            height: 350px;
            border: solid 1px $whitev2;
            border-radius: 1%;
            background-color: $white;
            .infoTitle{
                border-bottom: solid 3px $whitev2;
                height: 50px;
                padding: 15px 0px 0px 15px;
                text-align: start;
                font-weight: $extraBold;
                color: $darckGray;
            }
            .infoContent{
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: calc(100% - 50px);
                .info-content1{
                    font-weight: $bold;
                    font-family: 'Alegreya Sans', sans-serif !important;
                    font-size: 35px;
                }
                .info-content2{
                    font-weight: $bold;
                }
            }
        }
    }
}

@media (min-width: 1050px) and (max-width: 1200px) {
    .overview-page{
        .blocks-data{
            .blockdata{
                width: 150px;
                height: 100px;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 1050px) {
    .overview-page{
        .blocks-data{
            .blockdata{
                width: 120px;
                height: 80px;
                font-size: 14px;
            }
        }
    }
}