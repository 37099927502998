@import '../../../assets/constants/helpers/helpers.scss';

.field-2{
    clear: both;
    margin:14px 0 0 0 ;
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 48%;
    &.required{
        label{
            &::after{
                margin: -0.2em 0 0 0.2em;
                content: '*';
                color: $danger;
            }
        }
    }
    &.full-width{
        width: 100%;
    }
    label{
        display: block;
        margin: 0 0 0.28571429rem 0;
        color:$black;
        font-size: 16px;
        font-weight: $regular;
        text-transform: none;
    }
    input{
        font-family:$font;
        outline: 0;
        background: $white;
        box-shadow: 0 0 0 0 transparent inset;
        transition: color .1s ease,border-color .1s ease;
        height: 40px;
        border: 1px solid $gray;
        border-radius: 10px;
        font-style: normal;
        font-weight: $regular;
        font-size: 16px;
        line-height: 20px;
        text-align: start;
        padding: 0 20px;
        margin: 0;
        color: $gray;
        -webkit-appearance: none;
        -webkit-transition: color .1s ease,border-color .1s ease;
            &:focus{
                color: $black;
            border-color: $lightBlue;
            border-radius:12px;
            background: $white;
            box-shadow: 0 0 0 0 $SemitransparentBlack inset;
            }

    }
    input::placeholder{
        font-size: 14px;
        font-weight: $regular;
    }
}