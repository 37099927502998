@import '../../../assets/constants/helpers/helpers.scss';

.field-login{
    clear: both;
    font-family: $font;
    margin: 0 0 1em;
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 48%;
    position: relative;
    &.required{
        label{
            font-size: .92857143em;
            font-weight: $semiBold;
            &::after{
                margin: -0.2em 0 0 0.2em;
                content: '*';
                color: $danger;
            }
        }
    }
    &.full-width{
        width: 100%;
    }
    label{
        display: block;
        margin: 0 0 0.28571429rem 0;
        text-transform: none;
        font-family: $font;
        font-style: normal;
        font-weight: $regular;
        font-size: 16px;
        line-height: 20px;
        color: $black
    }
    input{
        font-family: $font;
        margin: 0;
        outline: 0;
        line-height: 1.21428571em;
        padding: 0.67857143em 1em;
        font-size: 1em;
        background: $white;
        color: $black;
        box-shadow: 0 0 0 0 transparent inset;
        -webkit-transition: color .1s ease,border-color .1s ease;
        transition: color .1s ease,border-color .1s ease;
        padding-left:  50px;
        padding-right:  50px;
        border: 1px solid $gray;
        border: 1px solid $gray;
        border-radius: 10px;
        height: 55px;
        &:focus{
            color: $black;
            border-color: $lightBlue;
            background: $white;
            box-shadow: 0 0 0 0 $darckGray;
        }
        }
    input::placeholder{
        font-family: $font;
        letter-spacing: 1.5px;
        font-style: normal;
        font-weight: $bold;
        font-size: 14px;
        line-height: 18px;
        color: $gray;
    }
    .icon{
        position: absolute;
        left: 10px;
        bottom: 20px;
        width: 18px;
        height: 18px;
    }
    .icon2{
        position: absolute;
        right: 10px;
        bottom: 20px;
        cursor: pointer;
    }
}