@import '../../../assets/constants/helpers/helpers.scss';

.insurance-form-moto{
    padding: 40px;
    border-radius: 10px;
    position: relative;
    width: 95%;
    min-height: 604px;
    background: #FFFFFF;
    
    .btns{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    .btn-back{
        left: 40px;
        bottom:  40px;
    }
    .btn-next{
        right:  40px;
        bottom:  40px;
    }
}
}