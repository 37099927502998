@import '../../../assets/constants/helpers/helpers.scss';

.propos-page{
    font-family: $font;
    .header{
        width: 100%;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 ;
        position: relative;
        background: linear-gradient(179.94deg, $transparentBlue -23.61%, $transparentBlack 99.95%);
        backdrop-filter: blur(125px);   
        .text{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title{
            width: 70%;
            font-style: normal;
            font-weight: $extraBold ;
            font-size: 51px;
            line-height: 52px;
            color: $darckGray;
            text-align: left;
            }
            .sub-title{
                width: 70%;
                font-style: normal;
                color: $black;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 51px;
            }
        }
        .image{
            display: flex;
            justify-content: center;
            align-items: center;
            padding:0;
            height: 100%;
            width: 60%;
            right: 0;
            .wrapper{
                height: 70%;
                width: 70%;  
                display: block;
                position: relative;
                .img{
                    width: 100%;
                    height: 100%;
                }  
                .rectangle{
                    width:120px ;
                    height:120px ;
                    background-color: $pink;
                    position: absolute;
                    top: -20px;
                    z-index: -1;
                    animation: mouvment 5s infinite;
                }     
                .ellipse{
                    width:120px ;
                    height:120px ;
                    background-color:$green ;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -35px;
                    right: -35px;
                    animation: mouvment-v2 8s infinite;
                }
            }
        }
        .secend-text{
            width: 605px;
            height: 14px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 21px;
            line-height: 14px;
            color: $black;
            position: absolute;
            bottom: 10%;
            left: 0;
        }
        .message-love{
            position: absolute;
            bottom: 20% ;
            right:1%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 70px;
            animation-name: mouvment-v2;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $darckGray;
                border-radius: 8.20116px;
                height: 50px;
                color: $darckBlue ;
                position: relative;
                .member{
                    position: absolute;
                    top: -50%;
                    left: 50%;
                }
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }
            .img{
                display: block;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        .message-rating{
            position: absolute;
            right: 34%;
            top: 37%;
            animation-name: mouvment;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .top{
                display: flex;
                justify-content: space-between;
                padding: 5px 10px 5px 5px;
                .sub-text{
                    font-style: normal;
                    font-weight: $semiBold ;
                    font-size: 10px;
                    line-height: 8px;
                    color: $darckBlue;
                }
            }
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $darckGray;
                border-radius: 8.20116px;
                height: 45px;
                color: $darckBlue;
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }

        }
    }
    @media (max-width :700px) {
        .header{
            height: 360px;
            .text{
                height: 100%;
                .title{
                font-weight: $semiBold;
                font-size: 18px;
                line-height: 20px;
                }
                .sub-title{
                    line-height: 20px; 
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
            .image{
                width: 100%;
                .wrapper{
                    height: 100%;
                    width: 100%;
                    .rectangle{
                        width:70px ;
                        height:70px ;
                        top: 90px;
                        left: -12px;
                    }     
                    .ellipse{
                        width:70px ;
                        height:70px ;
                        bottom: 96px;
                        animation: mouvment-v2-small-screen 8s infinite;;
                    }
                }       
            }
        }
    }
}

@keyframes mouvment {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-10px);
        
    }
    52%{
        transform: translateY(-9px);
        
    }
    100%{
        transform: translateY(0px);

    }
}

@keyframes mouvment-v2 {
    0%{
        right: -35px;
        bottom: -35px;
    }
    40%{
        transform: rotateY(180deg);
        z-index: -5;
        right: -55px;        
    }
    80%{

        transform: rotateX(+180deg);
        bottom: -115px;
        
    }
    100%{
        right: -35px;
        z-index: 1;
    }
}

@keyframes mouvment-v2-small-screen {
    0%{
        bottom: 96px;
        right: -35px;
    }
    40%{
        transform: rotateY(180deg);
        z-index: -5;
        right: -40px;        
    }
    80%{
        transform: rotateX(+180deg);
        bottom: 50px;
    }
    100%{
        right: -35px;
        z-index: 1;
    }
}