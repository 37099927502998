@import '../../../assets/constants/helpers/helpers.scss';

.insurance-form{
    position: relative;
    width: 100%;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btns{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    .btn-back{
        left: 40px;
        bottom:  40px;
    }
    .btn-next{
        right:  40px;
        bottom:  40px;
    }
}
}

@media (max-width: 700px) {
    .insurance-form{
        .btns{
            .btn-back{
                button{
                    font-size: 16px;
                    height: 35px;
                }
            }
            .btn-next{
                button{
                    font-size: 16px;
                    height: 35px;
                }
            }
        }
    }
}