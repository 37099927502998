@import '../../../assets/constants/helpers/helpers.scss';

.propos-page{
    .partenaires{
        width: 100%;
        margin-top: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font;
        .shape{
            fill: $green;
            position: absolute;
            left: 0;
            top: -40px;
        }
        .title{
            width: 605px;
            height: 33px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 23px;
            line-height: 33px;
            text-align: center;
            color: $black;
        }
        .text{
            width: 631px;
            height: 54px;
            font-style: normal;
            font-weight: $regular;
            font-size: 22px;
            line-height: 27px;
            text-align: center;
            color: $black;
            margin: 10px 0 30px 0 ;
        }
        .images{
            width: 100%;
            display: flex;
            justify-content: center;
            padding:  0 50px;
            .image{
                width: 200px;
                height: 90px;
                margin: 0 20px;
            }
        }
    }
    @media (max-width :700px) {
        .partenaires{
            margin-top: 40px;            
            .shape{
                width: 40px;
            }
            .title{
                font-size: 17px;
                margin-left: 5px;
            }
            .text{
                width: 90%;
                font-size: 16px;
            }
            .images{
                padding:  0 10px;
                flex-wrap: wrap;
                .image{
                    width: 150px;
                    margin: 5px 5px;
                }
            }
        }
    }
}