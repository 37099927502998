@import '../../../assets/constants/helpers/helpers.scss';

.home-page{
    .header{
        width: 100%;
        height: 65vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: linear-gradient(180deg, $transparentPink 0%, $transparentBlack 100%);
        backdrop-filter: blur(250px);
        position: relative;
        .text{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 10%;
            .title{
                width: 528px;
                height: 104px;
                font-style: normal;
                font-weight: $extraBold;
                font-size: 51px;
                line-height: 52px;
                color: $darckGray;
                text-align: left;
            }
            .sub-title{
                width: 527px;
                height: 93px;
                font-style: normal;
                font-weight: $regular;
                font-size: 25px;
                line-height: 31px;
                color: $black;
                text-align: left;
                margin-top: 20px;
            }
        }
        .image{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 1px 63px 0 0px;
            height: 100%;
            width: 50%;
        }
        .secend-text{
            height: 14px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 21px;
            line-height: 14px;
            color: $black;
        }
        .message-love{
            position: absolute;
            bottom: 12% ;
            left: 46%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 70px;
            animation-name: mouvment-text-v2;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $transparentBlack;
                border-radius: 8.20116px;
                height: 50px;
                color: $darckBlue ;
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }
            .img{
                display: block;
                margin-left: 20px;
            }
        }
        .message-rating{
            position: absolute;
            right: 3%;
            top: 27%;
            animation-name: mouvment-text;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            .top{
                display: flex;
                justify-content: space-between;
                padding: 5px 10px 5px 5px;
                .sub-text{
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 10px;
                    line-height: 8px;
                    color: $darckBlue ;
                }
            }
            .avi{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8.20116px 12.3017px 8.20116px 5px;
                gap: 5.13px;
                background: $white;
                box-shadow: 0px 25.6286px 15.3772px $transparentBlack;
                border-radius: 8.20116px;
                height: 45px;
                color: $darckBlue ;
                .change-color{
                    &:hover{
                        color: $lightBlue;
                    }
                }
            }
        }
    }
    @media (max-width :700px) {
        .header{
            height: 350px;
            .text{
                height: 100%;
                justify-content: start;
                padding-top: 15px;
                .title{
                    width: 200px;
                    font-size: 25px;
                    line-height: 30px;
                    height: 65px;
                }
                .sub-title{
                    width: 200px;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 5px;
                }
            }
            .image{
                width: 92%;
                .img{
                    position: absolute;
                    bottom: 0;
                    right: -77px;
                    width: 350px;
                    height: 350px;
                }
            }
            .secend-text{
                width: 300px;
                bottom: 33%;
                left: 5px;
                text-align: start;
            }
            .message-love{
                left: 3%;    
                .avi{
                    border-radius:4px;
                    height: 33px;
                }
                .img{
                    margin-left: 5px;
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }

    .insurances-links{
        width: 100%;
        height: 20vh;
        background-color: $pink;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(8,1fr);
        grid-template-rows: repeat(8,1fr);
        padding: 20px 0;
        transition: all 1s ease-in-out;
        &.fixed{
            position : fixed ;
            top: 0;
            z-index: 1 ;
            height: 100px;
            padding: 5px 10px;
            transition: all 1s ease-in-out;
            .half{
                width: 50%;
                height: auto;
                background-color: $pink;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding:  0 20px;
                transition: all 1s ease-in-out;
                .link{
                    width: calc(100%/6);
                    height: 80px;
                    padding: 6px 0 ;
                    &:hover{
                        border: 2px solid $white;
                    }
                    .name{
                        margin: 0;
                        width: 100%;
                        height: 50%;
                        font-style: normal;
                        font-weight: $regular;
                        font-size: 18px;
                        line-height: 23px;
                        text-align: center;
                        color: $white;
                        margin-top: 10px;
                    }
                }             
                :nth-child(2){
                    width: 30%;
                }
            }
        }
        .link{
            width: 100%;
            height: 90px;
            border: 2px solid $pink;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 ;
            cursor: pointer;
            &:hover{
                border: 2px solid $white;
            }
            .icon{
                width: 40px;
                height: 40px;
            }
            .name{
                width: 100%;
                height: 23px;
                font-style: normal;
                font-weight: $regular;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                color: $white;
            }
        }
    }
    @media (max-width :700px) {
        .insurances-links{
            width: 100%;
            grid-template-rows: repeat(2,1fr);
            grid-template-columns: repeat(4,25%);
            padding: 0;
            position: fixed;
            bottom: 0;
            z-index: 1;
            height: auto;
            &.fixed{
                top: auto;
                bottom: 0;
                padding:0;
                height: auto;
            }
            .link{
                width: 100%;
                height: 90px;
                border: 2px solid $pink;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0 ;
                cursor: pointer;
                &:hover{
                    border: 2px solid $white
                }
                .icon{
                    width: 30px;
                    height: 30px;
                }
                .name{
                    width: 114px;
                    height: 23px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 18px;
                    line-height: 16px;
                    text-align: center;
                    color: $white;
                    margin: 0;
                }
            }
        }
    }
}