@import '../../../assets/constants/helpers/helpers.scss';

.online{
    margin-top: 100px;
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 100px ;
    font-family: $font;
    .shape{
        fill: $green;
        position: absolute;
        left: 0;
    }
    .wrapper{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.bor{
            background: $white;
            box-shadow: 10px 0px 5px -6px $darckBlue;
            border-radius: 4.83095px;
        }
        .title-1{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            .rectangle2{
                width: 30px;
                height: 20px;
                border-radius:4px ;
                background-color: $blue ;
                margin: 0 40px 0 10px;
            }
        }
        .icons{
            width: 100%; 
            display: flex;               
            justify-content: center;
            align-items: center;
            position: relative;
            .sub-title{
                position: absolute;
                width: 100px;
                height: 18px;
                top: 0;
                left: 0;
                font-style: normal;
                font-weight: $medium;
                font-size: 11.1396px;
                line-height: 16px;
                color: $darckBlue;
                mix-blend-mode: normal
            }
            .wrapper-icon{
                display: flex;
                width: 50px;
                height: 80px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 20px;
                .icon{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $darckBlue;
                    fill: $darckBlue !important;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;
                    background: $white;
                    border: 0.928299px solid $lightGray;
                    border-radius: 9.28299px;
                    &:hover{
                        box-shadow: 11px 14px 29px -4px $SemitransparentBlack,0px 10px 15px -3px $SemitransparentBlack ;
                    }
                }
                .name{
                    height: 14.24px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 9.28299px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $darckBlue;
                    margin-top: 5PX;
                }
            }
        }
        .title{
                font-style: normal;
                margin-bottom: 15px;
                font-weight: $semiBold;
                font-size: 31px;
                line-height: 35px;
                color: $black;
                text-align: left;
                width: 495px;
            }
        .text{
            width: 495px;
            height: 93px;
            font-style: normal;
            font-weight: $regular;
            font-size: 22px;
            line-height: 31px;
            color: $black;
            text-align: left;
                        
        }  
    }
}
@media (max-width :700px) {
    .online{
        margin-top: 50px;
        height: auto;
        flex-direction: column-reverse;
        padding: 10px 5px ;
        .shape{
            bottom: -140px;
        }
        .wrapper{
            width: 100%;
            .icons{
                .wrapper-icon{
                    width: 25px;
                }
            }
            .title{
                    width: 100%;
                    font-size: 23px;
                    margin-top: 20px;
                }
            .text{
                width: 100%;
                height: auto;
                font-size: 17px;
                line-height: 22px;
                text-align: justify;
                padding: 10px 15px;
            }  
        }
    }
}
