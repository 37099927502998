@import '../../../../assets/constants/helpers/helpers.scss';

.step-form{
    font-family: $font;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    min-height: 345px;
    width: 95%;
    .group{
        display: flex;
        width: 100%;
        justify-content: center;
        input, select{
            width: 90%;
        }
        .field-1{
            clear: both;
            margin: 0 0 1em;
            display: flex;
            flex-direction: column;
            text-align: start;
            width: 48%;
            .Energie{
                display: block;
                margin: 0 0 0.28571429rem 0;
                color:$gray;
                font-size: 18px;
                font-weight: $regular;
                text-transform: none;
            }
            select{
                font-family:$font;
                outline: 0;
                background: $white;
                box-shadow: 0 0 0 0 transparent inset;
                transition: color .1s ease,border-color .1s ease;
                height: 55px;
                border: 2px solid $gray;
                border-radius: 12px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 20px;
                line-height: 30px;
                text-align: start;
                padding: 0 20px;
                margin: 0;
                color: $gray;
                -webkit-appearance: none;
                -webkit-transition: color .1s ease,border-color .1s ease;
                    &:focus{
                        color: $black;
                    border-color: $lightBlue;
                    border-radius:12px;
                    background: $white;
                    box-shadow: 0 0 0 0 $SemitransparentBlack inset;
                    }
        
            }
            .disabled{
                background-color: $lightGray;
                opacity: 0.7;
                color: black;
            }
        }
    }
    .erreur{
        color: red;
        margin-bottom: 15px;
        .err{
            margin-left: 3px;
        }
    }    
    // step 2
    .options-ac{
        width: 100%;
        display: flex;
        padding: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .option-ac{
            width: calc(30%);
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $danger;
            border-radius: 20px 20px 0px 0px;
            overflow: hidden;
            color: $danger;
            padding-bottom: 0 ;
            cursor: pointer;
            &.orange{
                color: $yellow ;
                border: 1px solid $yellow ;
            }
            &.kiwi{
                color: $green;
                border: 1px solid $green ;
            }
            .header{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                

                padding: 15px;
                .icon{
                        display: block;

                }
                .title{
                    font-style: normal;
                    font-weight: $extraBold;
                    font-size: 30px;
                    line-height: 38px;
                    margin-left: 15px;
                }
                 }

            .box-v2{
                width: 80%;
                margin: 20px;

                &.br{
                    border-top:1px dashed $gray;
                    border-bottom:1px dashed $gray;
                }
                .title{
                    font-style: normal;
                    font-weight: $semiBold ;
                    font-size: 18px;
                    line-height: 26px;
                }
                .services-pr{
                    display: flex;
                    flex-direction: column;
                    .service{
                        display: flex;
                        flex-direction: row;
                        .icon{
                            .close{
                                color: $danger;
                                font-weight: $semiBold;
                                font-size: 20px;
                            }
                            .done{
                                font-size: 18px;
                                color: $green;
                                font-weight: $semiBold;                            
                            }
                        }
                        .text{
                            margin: 0 5px;
                        }
                        .info-icon{
                            .info{
                                font-size: 16px;
                                color: $blue;                            
                            }
                        }
                    }
                }
                .price{
                    font-size: 30px;
                }
            }
           .commander{
            background-color: $danger;
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: $extraBold;
            font-size: 22px;
            line-height: 28px;
            color: $white;
            margin-bottom: 0;
            border: none;            
            cursor: pointer;

            &.orange-b{
                background-color: $yellow
            }
            &.kiwi-b{
                background-color: $green;
            }
           }
        }
        @media (max-width: 700px){
            flex-direction: column;
            .option-ac{
                width: 95%;
                margin: 10px 0;
            }
        }
    }
    // step 3 documents
    .cart{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:100%;
        margin: 20px 0;
        .wrapper-cart{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .text{
                margin-left: 25px;
                margin-bottom: 0;
                display: block;
                margin: 0 0 0.28571429rem 0;
                color: #C3C3C3;
                font-size: 0.92857143em;
                font-weight: 800;
                text-transform: none;
            }
            .recto,.verso{
                box-sizing: border-box;
                width: 545px;
                height: 148px;
                border: 2px dashed $gray;
                border-radius: 12px;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .placeholder{
                    position: relative;
                    bottom: 2vh;
                }
                .title1{
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    .blue{
                        color: $blue;
                    }
                }
                .title2{
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $gray;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
                label{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                        height: 100%;
                    .image{
                        width: 60%;
                        height: 80%;
                    }
                    .placeholder{
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    cursor: pointer;
                    input{
                        display: none;
                    }
                }
            } 
        }
    }
    .erreur{
        text-align: start;
        color: red;
        margin-bottom: 0px;
        margin-top: 0px;
        width:100%;
        .err{
            margin-left: 10px;
        }
    }
    // step 4 congrat
    .group-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cong-text{
            height: 46px;
            font-style: normal;
            font-weight: $regular;
            font-size: 32px;
            line-height: 46px;
            text-align: center;
            color: $darckBlue;
            .feles{
                font-weight: $semiBold;
            }
        }
    }

}

@media (max-width: 700px) {
    .step-form{
        .group, .cart{
            flex-direction: column;
            .field-1{
                width: 100%;
                label, .Energie{
                    margin-bottom: 1vh;
                    margin-top: 1vh;
                    font-size: 16px;
                }
                input, select{
                    font-size: 16px;
                    height: 40px;
                }
            }
        }
        .cart{
            margin-bottom: 0px;
            .wrapper-cart{
                width: 100% !important;
                margin-bottom: 2vh;
            }
        }
    }
}