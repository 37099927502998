@import '../../../assets/constants/helpers/helpers.scss';

.field-1{
    clear: both;
    margin: 0 0 1em;
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 48%;
    &.required{
        label{
            &::after{
                margin: -0.2em 0 0 0.2em;
                content: '*';
                color: $danger;
            }
        }
    }
    &.full-width{
        width: 100%;
    }
    label{
        display: block;
        margin: 0 0 0.28571429rem 0;
        color:$gray;
        font-size: .92857143em;
        font-weight: $extraBold;
        text-transform: none;
    }
    input{
        font-family:$font;
        outline: 0;
        background: $white;
        box-shadow: 0 0 0 0 transparent inset;
        transition: color .1s ease,border-color .1s ease;
        height: 55px;
        border: 2px solid $gray;
        border-radius: 12px;
        font-style: normal;
        font-weight: $semiBold;
        font-size: 20px;
        line-height: 30px;
        text-align: start;
        padding: 0 20px;
        margin: 0;
        color: $gray;
        -webkit-appearance: none;
        -webkit-transition: color .1s ease,border-color .1s ease;
            &:focus{
                color: $black;
            border-color: $lightBlue;
            border-radius:12px;
            background: $white;
            box-shadow: 0 0 0 0 $SemitransparentBlack inset;
            }

    }
    .disabled{
        background-color: $lightGray;
        opacity: 0.7;
        color: black;
    }
}