@import '../../../assets/constants/helpers/helpers.scss';

.propos-page{
    font-family: $font;
    .team{
        margin-top: 100px;
        width: 100%;
        height: 300px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 10px 100px ;
        .shape{
            fill: $yellow;
            position: absolute;
            right: 0;
            top: -20px;
        }
        .title{
            width: 390px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 25px;
            line-height: 30px;
            color: $black;
            text-align: left;
            margin-bottom: 20px;
        }
        .text{
            width: 495px;
            height: 93px;
            font-style: normal;
            font-weight: $regular;
            font-size: 22px;
            line-height: 31px;
            color: $black;
            text-align: left;
        }
    }
    @media (max-width :700px) {
        .team{
            margin-top: 150px;
            height: auto;
            flex-direction: column;
            padding: 10px 10px ;
            .shape{
                top: 250px;
            }
            .wrapper{
                width: 100%;
                margin-top: 26px;
                text-align: center;
                .title{
                    width: 100%;
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                }
                .text{
                    width: 100%;
                    height: auto;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: justify;
                }      
            }
        }  
    }
    .aventage{
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-top: 100px;
        .head{
            height: 35px;
            font-style: normal;
            font-weight: $semiBold;
            font-size: 31px;
            line-height: 35px;
            text-align: center;
            color: $black;
            margin-bottom: 20px;
        }
        .container{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .wrapper{
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &.center{
                    border-right:2px solid $gray ;
                    border-left: 2px solid $gray ;
                }
                .title{
                    width: 158px;
                    height: 31px;
                    font-style: normal;
                    font-weight: $semiBold;
                    font-size: 26px;
                    line-height: 31px;
                    text-align: center;
                    color: $black;
                    margin-top: 25px;
                    margin-bottom: 15px;
                }
                .text{
                    width: 274px;
                    height: 124px;
                    font-style: normal;
                    font-weight: $regular;
                    font-size: 22px;
                    line-height: 31px;
                    text-align: center;
                    color: $black;
                }
            }
        }
    }
    @media (max-width :700px) {
        .aventage{
            .container{
                flex-direction: column;
                .wrapper{
                    width: 70%;
                    padding: 10px 0 ;
                    &.center{
                        border-top:2px solid $gray ;
                        border-bottom: 2px solid $gray ;
                        border-left: none;
                        border-right: none;
                    }
                    .text{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }  
    }
}