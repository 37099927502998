@import '../../../assets/constants/helpers/helpers.scss';

.our-insurances{
    width: 100%;
    margin-top: 200px;
    font-family: $font;
    .title{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 0 0 100px;
        position: relative; 
        .center{
            width: 500px;
            min-height: 35px;
            text-align: start;
            font-style: normal;
            font-weight: $semiBold ;
            font-size: 23px;
            line-height: 33px;
            color: $black;
            margin-bottom: 30px;
                .transparent{
                font-weight: $semiBold;
                font-size: 43px;
                line-height: 43px;
                margin-bottom: 30px;
                color: $black;
                opacity: 0.1;
                margin-bottom: 10px;
            }
        }
        .shape{
            position: absolute;
            right: 0;
            fill: $yellow;
        }
    }
    .insurances{
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 100px;
        .insurance{
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .logo{
                background-color: $green;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                &.indiv{
                    background-color: $pink;
                }
            }
            .name{
                width: 205px;
                height: 62px;
                font-style: normal;
                font-weight: $semiBold;
                font-size: 25px;
                line-height: 31px;
                color: $black;
                text-align: left;
                margin-top: 15px;
            }
            .description{
                width: 407.04px;
                height: 186px;
                font-style: normal;
                font-weight: $regular;
                font-size: 25px;
                line-height: 31px;
                color: $black;
                margin-top:20px ;
                text-align: left;
            }
            .more{
                width: 179px;
                height: 21px;
                font-style: normal;
                font-weight: $semiBold ;
                font-size: 18px;
                line-height: 21px;
                color: $blue;
                cursor: pointer;
            }
        }
    }
}

@media (max-width :700px) {
    .our-insurances{
        margin-bottom: 200px;
        .title{
            padding: 20px 0 0 10px;
        }
        .insurances{
            flex-direction: column;
            margin-top: 10px;
            .insurance{
                width: 100%;
                justify-content: center;
                align-items: center ;
                margin-top: 40px;
                .name{
                    width: 100%;
                    height: auto;
                    text-align: center;
                }
                .description{
                    width: 100%;
                    height: 100px;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: justify;
                    padding: 0 20px;
                }
            }
        }
    }  
}
