@import "../../../../assets/constants/helpers/helpers.scss";

.assurances-container{
    width: 94%;
    min-height: 80vh;
    background-color: $white;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: $font;
    .header{
        width: 100%;
        height: 70px;
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title{
            display: flex;
            align-items: center;
            font-size: 22px;
            font-weight: $medium;
        }
        .options{
            display: flex;
            flex-direction: row;
            .option{
                display: flex;
                flex-direction: row;
                margin-right: 20px;
                .icon{
                    color: $gray;
                }
                .name{
                    color: $grayV2;
                    font-weight: $semiBold;
                    margin-left: 5px;
                    font-family: $font;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: $darckV2;
                }
            }
        }
    }
    .assurances-table{
        box-sizing: border-box;
        width: 100%;
        table{
            width: 100%;
            border-collapse: collapse;
            thead{
                tr{
                    border-bottom: 2.4px solid $gray;
                    th{
                        color: $grayV2;                        
                        font-family: $font;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.2px;
                    }  
                }
            }
            tbody{
                tr{
                    border-bottom: 1.4px solid $gray;
                    height: 100px;
                    td{
                        font-size: 16px;
                        font-weight: $medium;
                        font-family: $font;
                        font-style: normal;
                        font-weight: $semiBold;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: $darck;

                        .type{
                            font-family: $font;
                            font-style: normal;
                            font-weight: $regular;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.1px;
                            color: $grayV3;
                        }
                        .houre{
                            color: $grayV3;
                        }
                        .status{
                            border-radius: 10px;
                            box-sizing: border-box;
                            background-color: $danger;
                            padding: 5px 10px;
                            width: 90px;
                            height: 25px;
                            font-family: $font;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 11px;
                            line-height: 14px;
                            height: 34px;                           
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                            color: $white;
                            &.paid{
                                background-color: $green3;
                            }
                            &.partial{
                                background-color: $yellow;
                            }
                            &.unpaid{
                                background-color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 936px) {
    .assurances-container{
        .header{
            .title{
                font-size: 16px;
            }
            a{
                button{
                    font-size: 12px;
                }
            }
        }
    }
}
